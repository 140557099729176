import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import NavBar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import './Home.css';
import axios from "axios";
import CardMedia from '@material-ui/core/CardMedia';

const URL_API = process.env.REACT_APP_URL_API;

class Home extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
          events: []
        }
      }

    componentDidMount() {
        axios.get(`${URL_API}/event-list.php`).then(result => {
            this.setState({ events: result.data })
            console.log("", this.state.events)
        })
    }
    
    render() {
        return ( 
        <div>

            <NavBar title="DH TIMING"/>

            <div>
                <Typography variant="h5" align="center" className="title-home">
                    COMPETENCIAS
                </Typography>
            </div>
            <div className="events-container">
            {this.state.events.map((event) => {
                let imageSrc = `/img/eventos/${event.CODIGO}.png`
                
                return <Card className="item" key={event.CODIGO}>
                    <div className="event-image">
                        <img src={imageSrc}/>
                    </div>
                    <CardContent className="event-content">
                        <Typography align="left" className="event-title">
                            {event.NOMBRE2}
                        </Typography>
                        <Typography className="event-subtitle1" align="left">
                            {event.NOMBRE}
                        </Typography>
                        <Typography className="event-subtitle2" align="left">
                            {event.NOMBRE3}
                        </Typography>
                        <Typography align="left" className="event-date">
                            {event.FECHA}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Link to={`/event/${event.CODIGO}`} style={{textDecoration: 'none'}}>
                            <Button className="event-action-btn" variant="contained" color="primary">
                                VER TIEMPOS
                            </Button>
                        </Link>
                    </CardActions>
                </Card>
            })}
            </div>
            <Footer/>
        </div>
        );
    }
}
export default Home;