export const responsive = {

    //   desktop: {
    //     breakpoint: {
    //       max: 3000,
    //       min: 1024
    //     },
    //     items: 5,
    //     partialVisibilityGutter: 40
    //   },
    //   mobile: {
    //     breakpoint: {
    //       max: 464,
    //       min: 0
    //     },
    //     items: 3,
    //     partialVisibilityGutter: 100
    //   },
    //   tablet: {
    //     breakpoint: {
    //       max: 1024,
    //       min: 464
    //     },
    //     items: 4,
    //     partialVisibilityGutter: 30
    //   }




  //   superLargeDesktop: 
  //   {
  //     breakpoint: { max: 4000, min: 1700 },
  //     items: 9,
  //     slidesToSlide: 4
  //   },
  //   LargeDesktop: 
  //   {
  //     breakpoint: { max: 1700, min: 970 },
  //     items: 7,
  //     slidesToSlide: 4
  //   },
  //   desktop: {
  //     breakpoint: { max: 970, min: 768 },
  //     items: 6,
  //     slidesToSlide: 4
  //   },
  //   tablet: {
  //     breakpoint: { max: 768, min: 464 },
  //     items: 5,
  //     slidesToSlide: 4
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 4,
  //     slidesToSlide: 4
  //   }
  // };


  superLargeDesktop: 
  {
    breakpoint: { max: 4000, min: 1700 },
    items: 6,
    slidesToSlide: 4
  },
  LargeDesktop: 
  {
    breakpoint: { max: 1700, min: 970 },
    items: 5,
    slidesToSlide: 4
  },
  desktop: {
    breakpoint: { max: 970, min: 768 },
    items: 4,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 4,
    slidesToSlide: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    slidesToSlide: 3
  }
};

  export const AuspiciantesData = [
    //------- CPV y TACPY
    // {
    //   id: 1,
    //   imageurl: "/img/publicidad/cpv01.jpg",
    //   name: "CENTRO PARAGUAYO DE VOLANTES",
    //   price: "0",
    //   description: "CENTRO PARAGUAYO DE VOLANTES",
    //   link: "https://www.facebook.com/PRENSACPV/?locale=es_LA"
    // },
    // {
    //   id: 2,
    //   imageurl: "/img/publicidad/tacpy01.jpg",
    //   name: "TACPY",
    //   price: "0",
    //   description: "TACPY",
    //   link: "https://rally.com.py/"
    // },

    //---- AUSPICIANTES
    // {
    //   id: 3,
    //   imageurl: "/img/publicidad/trp01.jpg",
    //   name: "TIENDA RALLY PARAGUAY",
    //   price: "0",
    //   description: "TIENDA RALLY PARAGUAY",
    //   link: "https://es-la.facebook.com/tiendarallypy/"
    // },
    // HYUNDAI, REPSOL, SANGYONG, GEELY, ISUZU, MASERATI, HIUNDAI CONSTRUCTION, HYUNDAI TRUCK
  

    {
      id: 1,
      imageurl: "/img/carousel/automotor/AUTOMOTOR.webp",
      name: "AUTOMOTOR PARAGUAY",
      price: "0",
      description: "",
      link: "https://www.automotor.com.py/"
    },
    {
      id: 2,
      imageurl: "/img/carousel/automotor/HYUNDAI.webp",
      name: "AUTOMOTOR PARAGUAY",
      price: "0",
      description: "",
      link: "https://www.automotor.com.py/"
    },
    {
      id: 3,
      imageurl: "/img/carousel/automotor/SANGYONG.webp",
      name: "AUTOMOTOR PARAGUAY",
      price: "0",
      description: "",
      link: "https://www.automotor.com.py/"
    },
    {
      id: 4,
      imageurl: "/img/carousel/automotor/GEELY.webp",
      name: "AUTOMOTOR PARAGUAY",
      price: "0",
      description: "",
      link: "https://www.automotor.com.py/"
    },
    {
      id: 5,
      imageurl: "/img/carousel/automotor/ISUZU.webp",
      name: "AUTOMOTOR PARAGUAY",
      price: "0",
      description: "",
      link: "https://www.automotor.com.py/"
    },
    {
      id: 6,
      imageurl: "/img/carousel/automotor/MASERATI.webp",
      name: "AUTOMOTOR PARAGUAY",
      price: "0",
      description: "",
      link: "https://www.automotor.com.py/"
    },



  ];