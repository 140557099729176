import './AuspiciantesAutomotor2.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Auspiciantes from './Auspiciantes';
import { AuspiciantesData, responsive } from './AuspiciantesData';
import { styled } from '@material-ui/core';


export default function App() {

  const Ausp = AuspiciantesData.map((item) => (
    <Auspiciantes
      key={item.id}
      url={item.imageurl}
      name={item.name}
      price={item.price}
      description={item.description}
      openLink={item.link}
    />
  ));



  return (
    <div className="card bounce-in-bottom">
      <Carousel
        // responsive={responsive}
        // additionalTransfrom={0}
        // arrows
        // autoPlay
        // autoPlaySpeed={3000}
        // transitionDuration={3000}
        // centerMode={false}
        // className=""
        // containerClass="container-with-dots"
        // dotListClass=""
        // draggable
        // focusOnSelect={false}
        // infinite
        // itemClass=""
        // keyBoardControl
        // minimumTouchDrag={80}
        // pauseOnHover
        // renderArrowsWhenDisabled={false}
        // renderButtonGroupOutside={false}
        // renderDotsOutside={false}
        // rewind={false}
        // rewindWithAnimation={false}
        // rtl={false}
        // shouldResetAutoplay
        // showDots={false}
        // sliderClass=""
        // slidesToSlide={2}
        // swipeable
        // removeArrowOnDeviceType={["superLargeDesktop", "desktop", "tablet", "mobile"]}





    
        swipeable
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}//{this.props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={1000}
        keyBoardControl={false}
        transitionDuration={3000}
        containerClass="container-card"
        rewindWithAnimation={false}
        deviceType={responsive}
        removeArrowOnDeviceType={["superLargeDesktop", "LargeDesktop", "desktop", "tablet", "mobile"]}
        // dotListClass=""
        // itemClass="carousel-item-padding-40-px"
        customTransition="transform 1500ms ease-in-out"
      > 
      

        {Ausp}

      </Carousel>
    </div>
  );
};