import React from 'react';

export default function Auspiciantes(props) {
  return (
        <a href={props.openLink} target='_blank' rel='noopener noreferrer'>
            <img className='product--image' src={props.url} alt='slider' />
        </a>
    // <div className="card">
    //   <img className="product--image" src={props.url} alt="product image" />
    //   <h2>{props.name}</h2>
    //   <p className="price">{props.price}</p>
    //   <p>{props.description}</p>
    //   <a href={props.openLink} target='_blank' rel="noopener noreferrer">click</a>
    //   <p><button role='link' onClick={props.openLink}>click</button></p>
    // </div>
  );
};
