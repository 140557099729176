import React, { Component } from 'react';
import './Footer.css';
import { AppBar, Toolbar, IconButton, Typography, Button, Menu, MenuItem } from "@material-ui/core";
import { Link } from 'react-router-dom';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

class NavBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    render() {
        return (
            <div className="footer-container">
                <div className="footer-text">
                    © 2025 DH Timing. Todos los derechos reservados
                </div>
                {/* <div className="contact-link">
                <a target="_blank" href="https://api.whatsapp.com/send?phone=+595981727111&text=Hola.%20Me%20contacto%20desde%20dhtiming.com"><WhatsAppIcon/></a>
                </div> */}
            </div>
        );
    }
}
export default NavBar;