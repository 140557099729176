import React, { Component } from 'react';
import './Navbar.css';
import { AppBar, Toolbar, IconButton, Typography, Button, Menu, MenuItem } from "@material-ui/core";
import HomeIcon from '@material-ui/icons/Home';
import logo from "../../logo02.webp";
import { Link } from 'react-router-dom';

class NavBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            
        }
    }

    render() {
        return (
            
            <div className="container">
                <AppBar position="static">
                    <Toolbar>
                        <div className='logo_y_home_y_title'>
                            <div className="logo">
                                <Link to="/"><img className="logo-img" src={logo}/></Link>
                            </div>
                            
                            <div className='homeIcon'>
                                <IconButton edge="start" color="inherit" aria-label="menu">
                                    <Link to="/"><HomeIcon className='homeIcon-icon' style={{color: "#fff"}} /></Link>
                                </IconButton>
                            </div>

                            <div className='title'>
                                <Typography variant="h6" align="center" className="title-text">
                                    TIEMPOS ONLINE
                                </Typography>
                            </div>
                        </div>

  




                    </Toolbar>


                </AppBar>
                
            </div>

        );
    }
}
export default NavBar;