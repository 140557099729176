import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Home from "./pages/Home/Home";
//import Event from "./pages/Event/Event";
import Event from "./pages/Event/EventNew";
import EventLivePanel from "./pages/Event/EventLivePanel";
import EventLiveTimes from './pages/Event/EventLiveTimes';
// import Publicidad1 from "./components/Banner/AuspiciantesApp";
import Publicidad1 from "./components/Banner/AuspiciantesAppAutomotor";

//import "./components/Banner/Auspiciantes.css"

function App() {
  return (


    <BrowserRouter>

    <div className='page-container'>
      <div className='main-content'>
        <Switch>
          <Route path='/' component={Home} exact />
          <Route path='/event/:id' component={Event} exact />
          <Route path='/eventlivepanel/:id' component={EventLivePanel} exact />
          <Route path='/eventlivetimes/:id' component={EventLiveTimes} exact />
        </Switch>
      </div>


      {/* Carousel en la parte inferior */}
      <div className='carousel-wrapper'>
        <Publicidad1/>
      </div>
    </div>

    </BrowserRouter>

  );
}

export default App;
